import React from "react";
import "../App.css";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Card } from "react-bootstrap";

function Home() {
  return (
    <div class="App">
      <Card>
    <Card.Img variant="top" src="/images/sunsetheader.jpg" />
    <Card.Body>
      <Card.Text style={{fontSize: "30px", fontFamily: "-moz-initial"}}>
        "With every hardship comes ease"
      </Card.Text>
      <Card.Text style={{fontSize: "25px", fontFamily: "-moz-initial"}}>
        Welcome Aboard & Stay Tuned
      </Card.Text>
    </Card.Body>
  </Card>
    </div>
  );
}

export default Home;
